<template>
  <div>
    <div class="loading-wrapper" v-show="showLoading">
      <Loading></Loading>
    </div>
    <div class="register-wrapper" v-show="!showLoading">
      <img :src="imgUrl" alt="" width="100%" height="100%">
      <div id="register">
        <p class="title">用户注册</p>
        <el-form
          :model="ruleForm2"
          status-icon
          :rules="rules2"
          ref="ruleForm2"
          label-width="0"
          class="demo-ruleForm"
        >
          <!-- <el-form-item prop="username">
            <el-input v-model="ruleForm2.username" auto-complete="off" placeholder="请输入用户名"></el-input>
          </el-form-item> -->
          <el-form-item prop="tel">
            <el-input v-model="ruleForm2.tel" auto-complete="off" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item prop="pass">
            <el-input type="password" v-model="ruleForm2.pass" auto-complete="off" placeholder="输入密码必须由小写字母、大写字母、数字、特殊符号"></el-input>
          </el-form-item>
          <el-form-item prop="checkPass">
            <el-input type="password" v-model="ruleForm2.checkPass" auto-complete="off" placeholder="确认密码"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm2')" style="width:100%;">注册</el-button>
            <p class="login" @click="gotoLogin">已有账号？立即登录</p>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
  import { Register } from '@/api/user.js'
  import Loading from '@/components/loading/Loading.vue'

  export default {
    name: 'Register',
    components: {
      Loading
    },
    data () {
      // const checkName = (rule, value, callback) => {
      //   if (value.length < 6) {
      //     callback(new Error('输入长度大于6的用户名'))
      //   } else {
      //     callback()
      //   }
      // }
      const checkTel = (rule, value, callback) => {
        if (value.length !== 11) {
          callback(new Error('请输入正确的手机号码'))
        } else {
          callback()
        }
      }
      const validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('输入密码必须由小写字母、大写字母、数字、特殊符号'))
        } else {
          if (this.ruleForm2.checkPass !== '') {
            this.$refs.ruleForm2.validateField('checkPass')
          }
          callback()
        }
      }
      const validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'))
        } else if (value !== this.ruleForm2.pass) {
          callback(new Error('两次输入密码不一致!'))
        } else {
          callback()
        }
      }
      return {
        showLoading: true,
        imgUrl: require('../../assets/img/欢迎.png'),
        ruleForm2: {
          // username: '',
          pass: '',
          checkPass: '',
          email: '',
          smscode: '',
          tel: '',
          address: '',
          region: ''
        },
        rules2: {
          // username: [{
          //   validator: checkName,
          //   trigger: 'change'
          // }],
          pass: [{
            validator: validatePass,
            trigger: 'change'
          }],
          checkPass: [{
            validator: validatePass2,
            trigger: 'change'
          }],
          tel: [{
            validator: checkTel,
            trigger: 'change'
          }],
          smscode: [{
            trigger: 'change'
          }]
        },
        buttonText: '发送验证码',
        isDisabled: false, // 是否禁止点击发送验证码按钮
        flag: true
      }
    },
    mounted () {
      const bgImg = new Image()
      bgImg.src = this.imgUrl
      bgImg.onerror = () => {
        console.log('img onerror')
      }
      bgImg.onload = () => { // 图片加载成功后 去除 loading
        this.showLoading = false
      }
    },
    methods: {
      sendCode () {
        const email = this.ruleForm2.email
        // if (this.checkEmail(email) && this.ruleForm2.username) {
        if (this.checkEmail(email) && this.ruleForm2.tel) {
          const from = {}
          // 发送验证码
          Register(
            from
          ).then((res) => {
            if (res.status === 200 && res.data && res.data.code === 0) {
              this.$notify({
                title: '成功',
                message: '验证码发送成功，请注意查收。有效期5分钟。',
                duration: 1000,
                type: 'success'
              })

              let time = 300
              this.buttonText = '已发送'
              this.isDisabled = true
              if (this.flag) {
                this.flag = false
                const timer = setInterval(() => {
                  time--
                  this.buttonText = time + ' 秒'
                  if (time === 0) {
                    clearInterval(timer)
                    this.buttonText = '重新获取'
                    this.isDisabled = false
                    this.flag = true
                  }
                }, 1000)
              }
            } else {
              this.$notify({
                title: '失败',
                message: res.data.msg,
                duration: 1000,
                type: 'error'
              })
            }
          })
        }
      },
      submitForm (formName) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            const from = {
              address: this.ruleForm2.address,
              password: this.ruleForm2.checkPass,
              sex: this.ruleForm2.region,
              tel: this.ruleForm2.tel,
              username: this.ruleForm2.tel
              // username: this.ruleForm2.username
            }
            Register(
              from
            ).then((resp) => {
              if (resp.data.code === '0') {
                this.$notify({
                  title: '成功',
                  message: '注册成功。',
                  type: 'success'
                })
                setTimeout(() => {
                  this.$router.push({
                    path: '/login'
                  })
                }, 500)
              } else if (resp.data.code === '406') {
                this.$notify({
                  title: '错误',
                  message: resp.data.msg,
                  type: 'error'
                })
              } else if (resp.data.code === '405') {
                this.$notify({
                  title: '错误',
                  message: '账号已经存在',
                  type: 'error'
                })
              } else {
                this.$notify({
                  title: '错误',
                  message: '服务器请求出错',
                  type: 'error'
                })
              }
            })
          }
        })
      },
      gotoLogin () {
        this.$router.push({
          path: '/login'
        })
      },
      // 验证邮箱
      checkEmail (str) {
        const re = /^(?:\w+\.?)*\w+@(?:\w+\.)*\w+$/
        if (re.test(str)) {
          return true
        } else {
          return false
        }
      }
    }
  }
</script>

<style scoped>
  .loading-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #aedff8;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .register-wrapper img {
    position: absolute;
    left: 0;
    z-index: 1;
  }

  .register-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  #register {
    max-width: 340px;
    margin: 60px auto;
    background: #fff;
    padding: 20px 40px;
    border-radius: 10px;
    position: relative;
    z-index: 9;
  }

  .title {
    font-size: 26px;
    line-height: 50px;
    font-weight: bold;
    margin: 10px;
    text-align: center;
  }

  .el-form-item {
    text-align: center;
  }

  .login {
    margin-top: 10px;
    font-size: 14px;
    line-height: 22px;
    color: #1ab2ff;
    cursor: pointer;
    text-align: left;
    text-indent: 8px;
    width: 160px;
  }

  .login:hover {
    color: #2c2fd6;
  }

  .code >>> .el-form-item__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .code button {
    margin-left: 20px;
    width: 140px;
    text-align: center;
  }

  .el-button--primary:focus {
    background: #409EFF;
    border-color: #409EFF;
    color: #fff;
  }

  @media (max-width: 768px) {
    #register {
      max-width: 260px;
      margin: 60px auto;
      background: #fff;
      padding: 10px 20px;
      border-radius: 10px;
      position: relative;
      z-index: 9;
    }
  }
</style>
